<template>

        <div class="row mt-4">
          <div class="col-12">
            <div class="card">
              <div class="card-header justify-content-between">
                <div class="input-group input-group-flush d-flex flex-row-reverse">
                  <input v-model.trim="filter.search" class="form-control list-search" type="search" placeholder="Search" @keyup.enter.prevent="fetchFleet(true)" />
                  <span class="input-group-text border-0">
                    <i class="fe fe-search pointer" @click.prevent="fetchFleet(true)"></i>
                  </span>
                </div>
                <div class="col-auto" v-if="totalRecords">
                  <v-datepicker style="width: 100%" v-model="filter.range" placeholder="Filter by date" range></v-datepicker>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="mb-1 py-3" v-if="vehicleCategories && !loading">
                  <div class="row d-flex justify-content-between align-items-center px-4">
                    <div class="pr-5 flex gap-4 items-center">
                      <download-button v-if="tableData.length" :downloading="downloadingReport" label="Download Report" @download="downloadReport()"></download-button>
                      <div class="col">
                        <label for="status_active" class="d-block-inline px-2 mb-0">
                          <input type="radio" v-model="statusFilter" name="status" value="active" id="status_active" />
                          Active
                        </label>
                        <label for="status_inactive" class="d-block-inline px-2 mb-0">
                          <input type="radio" v-model="statusFilter" name="status" value="inactive" id="status_inactive" />
                          Inactive
                        </label>
                      </div>
                    </div>
                    <div class="col col-5 d-flex align-items-center flex-wrap">
                      <div class="col">
                        <v-select v-model="filter.vehicleType" @input="filterApplied = false" :options="vehicleCategories" :reduce="(category) => category.name" placeholder="Select a vehicle type" required>
                          <template v-slot:option="type">
                            <span>{{ type.name }}</span>
                          </template>
                          <template v-slot:selected-option="type">
                            <span>{{ type.name }}</span>
                          </template>
                        </v-select>
                      </div>
                      <button v-if="!filterApplied" class="btn btn-primary btn-sm ml-1 h-2r" @click="applyCategoryFilter">
                        Apply Filter
                      </button>
                      <button v-else class="btn btn-primary btn-sm ml-1 h-2r" @click="clearFilter">
                        Clear filter
                      </button>
                    </div>


                  </div>
                </div>

                <b-table striped hover selectable responsive :items="tableData" :fields="columns" :current-page="currentPage" :busy="loading" :empty-text="'No vehicles available.'" show-empty>
                  <template #empty="scope">
                    <h4 class="text-center">{{ scope.emptyText }}</h4>
                  </template>

                  <template #table-busy>
                    <div class="d-flex flex-column justify-content-center align-items-center">
                      <div class="spinner-border" role="status"></div>
                      <p class="text-center mt-4">
                        <strong>Loading...</strong>
                      </p>
                    </div>
                  </template>

                  <template #cell(vehicle)="data">
                    <div class="d-flex align-items-center">
                      <image-zoom style-class="avatar-sm mr-1">
                        <template v-slot:image>
                          <img :src="generateQRCodeImageUrl(data.item)" alt="QR Code" class="avatar avatar-sm" />
                        </template>
                      </image-zoom>
                      <div class="avatar avatar-sm">
                        <img src="@/assets/img/default-bus.png" class="avatar-img" alt="Bus" />
                      </div>
                      <p class="ml-2 mt-2">
                        <router-link class="sh-page-link" :to="{
                          name: 'ShowVehicle',
                          params: {
                            vehicleId: data.item.id,
                            partnerId: data.item.partner_id,
                          },
                        }"><img v-if="data.item.tracking_id?.length" src="@/assets/img/gps.png" width="15" height="15" alt="Vehicle has tracker onboard" />
                          {{ data.item.brand }} {{ data.item.name }}
                        </router-link>
                      </p>
                    </div>
                  </template>
                  <template #cell(plateNumber)="data">
                    <router-link class="sh-page-link" :to="{
                      name: 'ShowVehicle',
                      params: {
                        vehicleId: data.item.id,
                        partnerId: data?.item?.partner_id,
                      },
                    }">{{ data.item.registration_number }}</router-link>
                  </template>
                  <template #cell(capacity)="data">
                    {{ data.item.seats }}
                  </template>
                  <template #cell(type)="data">
                    {{ data.item.type }}
                  </template>
                  <template #cell(vehicleAmenities)="data">
                    {{ data.item.amenities }}
                  </template>
                  <template #cell(averageRating)="data">
                    <average-rating :rating="data.item.average || 0" :total-trips="data.item.trip_count || 0">
                    </average-rating>
                  </template>
                  <template #cell(driver)="data">
                    <router-link class="sh-page-link" :to="{
                      name: 'ShowDriver',
                      params: { driverId: data.item?.drivers[0]?.id },
                    }">{{
  data.item.drivers &&
  (data.item?.drivers[0]?.lname ||
    data.item?.drivers[0]?.fname)
  ? `${data.item?.drivers[0]?.lname} ${data.item?.drivers[0]?.fname}`
  : 'N/A'
}}
                    </router-link>
                  </template>
                  <template #cell(date)="data">
                    {{ data.item.created_at | date('yyyy-MM-dd') }}
                  </template>
                </b-table>
              </div>
              <div class="card-footer" v-if="totalRecords && !loading">
                <div class="row align-items-center">
                  <div class="col">
                    <span class="text-dark font-weight-bold">{{
                      tablePageText
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize">
                    </b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script setup>
import DownloadButton from '@/components/core/DownloadButton.vue'
import AverageRating from '@/components/core/AverageRating.vue'
import ImageZoom from '@/components/core/ImageZoom'
import Vue from '@/main'
import { ExportToCsv } from 'export-to-csv'
import { computed, ref, watch } from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import moment from 'moment'
import { joinWithRatingsData, extractErrorMessage } from '@/utils/helpers'
import { format } from 'date-fns'

const columns = ref([
  {key: 'vehicle',label: 'Vehicle'},
  {key: 'plateNumber',label: 'Plate Number'},
  {key: 'capacity',label: 'Capacity'},
  {key: 'type',label: 'Type'},
  {key: 'vehicleAmenities',label: 'Vehicle Amenities'},
  {key: 'averageRating',label: 'Average Rating'},
  {key: 'driver',label: 'Driver'},
  {key: 'date',label: 'Date Added'}
])

const filter = ref({
  range: ref([]),
  search: '',
  vehicleType: null
})
const settingsId = ref('')
const pageSize = ref(10)
const currentPage = ref(1)
const statusFilter = ref('active')
const totalRecords = ref(0)
const totalPages = ref(0)
const loading = ref(false)
const tableData = ref([])
const ratingData = ref(null)
const vehicleCategories = ref([])
const filterApplied = ref(false)
const downloadingReport = ref(false)



const tablePageText = computed(() =>{
  const cpSize = pageSize.value * currentPage.value
  return totalRecords.value
    ? `Showing ${cpSize - pageSize.value + 1} - ${Math.min(
      cpSize,
      totalRecords.value
    )} of ${totalRecords.value}`
    : ''
})
const generateQRCodeImageUrl = (vehicle) =>{
  const encodedUrl = encodeURIComponent(
    `https://shuttlers.ng/#/vehicles/${vehicle.id}`
  )
  return `https://chart.googleapis.com/chart?cht=qr&choe=UTF-8&chs=512x512&chl=${encodedUrl}`
}
const applyCategoryFilter = () =>{
  fetchFleet(true)
}

const clearFilter = () =>{
  if (filterApplied.value) {
    filterApplied.value = false
    filter.value.vehicleType = null
    fetchFleet(true)
  }
}

const downloadReport = async () =>{
  const dateRange = filter.value.range.map((date) =>
    moment(date).format('YYYY-MM-DD')
  )
  try {
    Vue.config.globalProperties.$Progress.start()
    downloadingReport.value = true
    const results = await axios.get(
      `/v1/vehicles?metadata=true&include=trip_count&page=${currentPage.value
      }&limit=${totalRecords.value}${dateRange[0] ? `&start_date_filter=${dateRange[0]}` : ''
      }${filter.value.vehicleType ? `&type=${filter.value.vehicleType}` : ''
      }${dateRange[1] ? `&end_date_filter=${dateRange[1]}` : ''}`
    )
    const fleets = results.data.data
    Vue.config.globalProperties.$Progress.finish()
    const csvData = fleets.map((data) =>
    {
      return {
        vehicle: data.brand + ' ' + data.name,
        plate_number: data.registration_number,
        capacity: data.seats,
        type: data.type,
        vehicle_amenities: data.amenity_list.map((amenity) =>
        {
          return amenity?.name
        }),
        totalTrips: data.trip_count,
        driver: data?.driver?.fname + ' ' + data?.driver?.lname,
        date_joined: moment(data.created_at).format('YYYY-MM-DD')
      }
    })

    const csvParams = {
      fieldSeparator: ',',
      filename:
        Array.isArray(dateRange) && !dateRange.length
          ? 'Fleet List'
          : `Fleet List from ${dateRange[0]}  to ${dateRange[1]}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title:
        Array.isArray(dateRange) && !dateRange.length
          ? 'Fleet List'
          : `Fleet List from ${dateRange[0]}  to ${dateRange[1]}`,
      useTextFile: false,
      useBom: true,
      headers: [
        'Vehicle',
        'PlateNumber',
        'Capacity',
        'Type',
        'Amenities',
        'TotalTrips',
        'Driver',
        'Date_Joined'
      ]
    }

    const csvExporter = new ExportToCsv(csvParams)

    csvExporter.generateCsv(csvData)

    Swal.fire({
      icon: 'success',
      title: 'Report downloaded',
      text: 'Report has been downloaded successfully',
      showCloseButton: true
    })
  } catch (error) {
    Vue.config.globalProperties.$Progress.fail()
  } finally {
    downloadingReport.value = false
  }
}
const dateFilterValid = computed(() =>{
  return (
    filter.value.range.length &&
    filter.value.range.filter((date) => !!date).length > 0
  )
})

const fetchFleet = async (showLoader) =>
{
  if (showLoader) {
    loading.value = true
    currentPage.value = 1
  }

  const limit = pageSize.value
  const page = currentPage.value
  const query = filter.value.search || ''
  const baseUrl = query ? '/v1/vehicles/search' : '/v1/vehicles'

  let url = `${baseUrl}?limit=${limit}&page=${page}&status=${statusFilter.value}&metadata=true&include=trip_count&related=vehicle_type,partner,drivers`
  const [from, to] = filter.value.range
  if (from || to) {
    url += `&start_date_filter=${from ? format(from, 'yyyy-MM-dd') : ''
      }&end_date_filter=${to ? format(to, 'yyyy-MM-dd') : ''}`
  }
  if (filter.value.vehicleType) {
    url += `&type=${filter.value.vehicleType}`
    filterApplied.value = true
  }

  try {
    const result = await axios({
      url,
      method: query ? 'POST' : 'GET',
      data: {
        search_term: query
      }
    })
    if (!ratingData.value) {
      try {
        ratingData.value = await fetchRatingData()
      } catch (e) {
        ratingData.value = { data: { data: [] } }
      }
    }
    tableData.value = joinWithRatingsData(
      result.data.data,
      ratingData.value.data.data
    )
    totalPages.value = result.data.metadata.total_pages
    totalRecords.value = result.data.metadata.total
  } catch (e) {
    const err = extractErrorMessage(e)

  } finally {
    loading.value = false
  }
}
const fetchTripRatingSettings = async (serviceId) =>
{
  const tripRatingSettingsResponse = await axios.get(
    `/rating/settings/service-id/${serviceId}`
  )
  return tripRatingSettingsResponse.data.data
}

const fetchRatingData = async () =>
{
  const { reference } = await fetchTripRatingSettings(
    process.env.VUE_APP_TRIP_RATING_SERVICE_ID
  )
  settingsId.value = reference
  const ratingsPayload = {
    settings_id: settingsId.value,
    parameter: 'vehicle_id',
    start_date: '2022-01-01',
    end_date: `${moment(new Date()).format('YYYY-MM-DD')}`
  }
  return await axios.post('/rating/reports/generic', ratingsPayload)
}

const fetchVehicleCategories = () =>{
  axios
    .get('/v1/vehicle-types', {})
    .then((res) =>
    {
      vehicleCategories.value = res.data.data
    })
    .finally(() => { })
}

watch(currentPage, () => { fetchFleet() })
watch(statusFilter, () => { fetchFleet(true) })
watch(dateFilterValid, () => { fetchFleet(true) })

fetchVehicleCategories()
fetchFleet(true)
</script>

<style scoped>

</style>